const Faq = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="70326c9b-4a0f-429b-9c76-792941e326d5"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#70326c9b-4a0f-429b-9c76-792941e326d5)"
                    width="52"
                    height="24"
                  />
                </svg>
                
              </span><span className=" text-blue-500">Frequently Asked Questions</span>
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              We've answered these questions before you asked.
            </p>
          </div>
        </div>
        <div className="max-w-screen-xl sm:mx-auto">
          <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-2">
            <div className="space-y-8">
              <div>
                <p className="mb-4 text-xl font-medium">
                  What makes this program different from the rest? Why should I enrol?
                </p>
                <p className="text-gray-700">
                    <span className="text-blue-500 font-bold">Experience is our watchword.</span>
                    <br />
                    <br />
                    This mentorship is geared to giving candidates the experience of
                    working in a tech environment. For us it more of hands-on than learning.
                    Don't get us wrong, learning is part of this but we want candidates to have
                    proof of what they have learned.
                </p>
              </div>
              <div>
                <p className="mb-4 text-xl font-medium">
                  How do I enrol?
                </p>
                <p className="text-gray-700">
                <a href="https://forms.gle/BdYL1VFctTBRcDeW7"><span className="text-blue-500 font-bold">Click Here!</span></a>
                  <br />
                  <br />
                  Fill out the form to register your interest and we will reach out to you
                  on next steps.
                </p>
              </div>
              <div>
                <p className="mb-4 text-xl font-medium">
                  Who is the program for?
                </p>
                <p className="text-gray-700">
                <span className="text-blue-500 font-bold">This program is for you if </span>
                <br />
                <br />
                  You want to know how to write programs and you do not know where to start.<br />
                  You have learnt a language and can write basic programs but you do not 
                  know what to do next. <br />You are looking for experience building real-world apps.
                </p>
              </div>
            </div>
            <div className="space-y-8">
              <div>
                <p className="mb-4 text-xl font-medium">
                  How much does it cost?
                </p>
                <p className="text-gray-700">
                <span className="text-blue-500 font-bold">Not much</span>
                  <br />
                  <br />
                  There are 2 payment plans. The program lasts for 6 months and you can pay a one-time 
                  fee of ₦155,000. If that is too much that's fine, there's a two-time payment option.
                  Make an initial deposit of ₦100,000 then ₦60,000 4 months later. Register your interest
                  here as there are limited slots.
                </p>
              </div>
              <div>
                <p className="mb-4 text-xl font-medium">
                  When does the program start?
                </p>
                <p className="text-gray-700">
                <span className="text-blue-500 font-bold">Immediately</span>
                  <br />
                  <br />
                  Onboarding commences immediately. We will use onboarding to help you
                  get seetled with the mentorship experience. Once onboarding is completed you begin the program.
                </p>
              </div>
              <div>
                <p className="mb-4 text-xl font-medium">
                  For More Information
                </p>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc1Gm3nwj7iN8zVudCTarSaH_HfaVuAYmsPJyNmIG8l_FSftg/viewform?embedded=true" width="640" height="709" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                <p className="text-gray-700">
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default Faq