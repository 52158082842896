import Content from "../components/content/Content";
import Details from "../components/content/Details";
import Features from "../components/content/Features";
import Header  from "../components/header/Header";
import { Nav } from "../components/navbar/Navbar";
import Pricing from "../components/pricing/Pricing";
import Team from "../components/team/Team";

export default function Landing(){
    return(
        <>
            <main>
                <section>
                    <Nav />
                    <Header />
                    <Details />
                    <Features />
                    <Team />
                    <Pricing />
                </section>
            </main>
            
        </>
    );
}