import { BsLinkedin, BsGithub, BsGlobe2 } from'react-icons/bs';
const Team = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
          <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-teal-900 uppercase bg-blue-400">
            MENTORS
          </p>
          <p className="text-base text-gray-700 md:text-lg">
            Meet your expert mentors.
          </p>
        </div>
        <div className="grid gap-10 mx-auto lg:grid-cols-2 lg:max-w-screen-lg">
          <div className="grid sm:grid-cols-3">
            <div className="relative w-full h-48 max-h-full rounded shadow sm:h-auto">
              <img
                className="absolute object-cover w-full h-full rounded"
                src="/mk.JPG"
                alt="Person"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
              <p className="text-lg font-bold">Akande Victor</p>
              <p className="mb-4 text-xs text-blue-500">Team Lead</p>
              <p className="mb-4 text-sm tracking-wide text-gray-800">
                Victor is a software engineer with over 4 years experience building a variety of applications.<br />
                He currently works with the World Bank funded <a href="https://ace.covenantuniversity.edu.ng" className='text-blue-500'>CApIC-ACE</a>. Here he is working with other engineers building
                cloud applications and a Federated Genomics private Cloud infrastructure.

              </p>
              <div className="flex items-center space-x-3">
                <a
                  href="https://www.linkedin.com/in/victor-akande-570a35148/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <BsLinkedin className="text-blue-500" />
                </a>
                <a
                  href="https://github.com/akandeav"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                   <BsGithub className='text-blue-500' />
                </a>
                <a
                  href="https://akande.com.ng"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                   <BsGlobe2 className='text-blue-500' />
                </a>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-cols-3">
            <div className="relative w-full h-48 max-h-full rounded shadow sm:h-auto">
              <img
                className="absolute object-cover w-full h-full rounded"
                src="/lights.jpeg"
                alt="Person"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
              <p className="text-lg font-bold">Opeoluwa Oyedeji</p>
              <p className="mb-4 text-xs text-blue-500">Co-Mentor</p>
              <p className="mb-4 text-sm tracking-wide text-gray-800">
                Opeoluwa is a Game developer with an eye for finding optimal solutions.
                Check out his chess game <a href="https://daniel-ope.itch.io/mine-chess" className='text-blue-500'>here</a>. It is not the regular chess
                you are used to.
              </p>
              <div className="flex items-center space-x-3">
                <a
                  href="https://www.linkedin.com/in/opeoluwa-oyedeji-600399174/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <BsLinkedin className="text-blue-500" />
                </a>
                <a
                  href="https://github.com/Daniel-Ope06"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <BsGithub className='text-blue-500' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default Team