import { BsFillCloudFill } from 'react-icons/bs'
import { FaBrain, FaDatabase } from 'react-icons/fa'
import { MdOutlineWork, MdWorkspacesFilled } from 'react-icons/md'
import { BiPlanet } from 'react-icons/bi'
import { BsFillGearFill } from 'react-icons/bs'

const Features = () => {
    return (
      <div className="bg-gray-100">
        <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        
          <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0">
            <svg
              viewBox="0 0 88 88"
              className="w-full max-w-screen-xl text-blue-100"
            >
              <circle fill="currentColor" cx="44" cy="44" r="15.5" />
              <circle
                fillOpacity="0.2"
                fill="currentColor"
                cx="44"
                cy="44"
                r="44"
              />
              <circle
                fillOpacity="0.2"
                fill="currentColor"
                cx="44"
                cy="44"
                r="37.5"
              />
              <circle
                fillOpacity="0.3"
                fill="currentColor"
                cx="44"
                cy="44"
                r="29.5"
              />
              <circle
                fillOpacity="0.3"
                fill="currentColor"
                cx="44"
                cy="44"
                r="22.5"
              />
            </svg>
          </div>
          <div className="relative mx-auto mb-10 lg:max-w-xl sm:text-center">
          <p className="inline-block px-3 py-px text-lg font-semibold tracking-wider uppercase bg-blue-400">
            What We'll Guide you Through
          </p>
        </div>
          <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <BsFillCloudFill />
                </div>
                <p className="mb-2 font-bold">The Internet</p>
                <p className="text-sm leading-5 text-gray-900">
                  Introduction to how the internet works.
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <FaBrain />
                </div>
                <p className="mb-2 font-bold">Logic and Critical Thinking</p>
                <p className="text-sm leading-5 text-gray-900">
                  The reason you're a programmer, Problem Solving. 
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <svg
                    className="w-8 h-8 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <p className="mb-2 font-bold">Programming Languages</p>
                <p className="text-sm leading-5 text-gray-900">
                  Python, Javascript and Golang.
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <MdWorkspacesFilled />
                </div>
                <p className="mb-2 font-bold">Data Structures and Algorithms</p>
                <p className="text-sm leading-5 text-gray-900">
                  Arrays, lists, graphs, Sorting, searching...the whole shebang
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <FaDatabase />
                </div>
                <p className="mb-2 font-bold">Databases</p>
                <p className="text-sm leading-5 text-gray-900">
                  SQL, NoSQL
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <BsFillGearFill />
                </div>
                <p className="mb-2 font-bold">Operating Systems</p>
                <p className="text-sm leading-5 text-gray-900">
                  How do they work and what can we do with them?
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <MdOutlineWork />
                </div>
                <p className="mb-2 font-bold">Work Experience</p>
                <p className="text-sm leading-5 text-gray-900">
                  By the end of the mentorship, you will have work experience from building and 
                  deploying applications with users not just localhost &#127773;
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-10 h-10 mb-4 rounded-full bg-blue-100 text-blue-500">
                  <BiPlanet />
                </div>
                <p className="mb-2 font-bold">To Infinity and beyond</p>
                <p className="text-sm leading-5 text-gray-900">
                 Version control, DevOps, Security, Support &#129327; 
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Features