const Header = () => {
    return (
      <div className="flex flex-col justify-between rounded-xl shadow-lg bg-blue-100 max-w-4xl px-4 mx-auto lg:pt-16 lg:flex-row md:px-8 lg:max-w-screen-xl">
        <div className="pt-16 mb-16 lg:mb-0 lg:pt-32 lg:max-w-lg lg:pr-5">
          <div className="max-w-xl mb-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider uppercase  bg-blue-400">
                Beginner
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Software Engineering
              <br className="hidden md:block" />
              
              <span className="inline-block text-blue-500">
                Mentorship program
              </span>
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              If you've asked yourself, <span className="text-blue-500 font-bold">where do i fit in the tech world?</span><br />
              <span className="text-blue-500 font-bold">What language do i learn? What can i do with programming languages? </span>
              Then this program is for you. We've created this to make it easy for you to 
              choose want you want to do as a programmer.
            </p>
          </div>
          <div className="flex mb-2 items-center">
            <a
              href="https://youtu.be/2c548DSnAC0"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none"
            >
              Watch intro
            </a>
            <a
              href="/faq"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              FAQs
            </a>
          </div>
        </div>
        <div>
          <img
            src="/ill-2.png"
            className="object-cover object-top w-full h-64 mx-auto lg:h-auto xl:mr-24 md:max-w-sm"
            alt=""
          />
        </div>
      </div>
    );
  };
export default Header;