import { BsInfoSquareFill, BsFillFlagFill, BsFillFileEarmarkFill } from 'react-icons/bs'
import { BiTimeFive, BiSupport } from 'react-icons/bi'
import { GiMuscleUp } from 'react-icons/gi'

const Details = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-blue-100 lg:w-20 lg:h-20">
          <BsInfoSquareFill className="text-blue-400 w-10 h-10 lg:w-16 lg:h-16" />
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Program Details
            </h2>
            <p
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Onboarding begins once you enrol
            </p>
          </div>
          <div className="lg:w-1/2">
            <ul>
                <li className='flex items-center'>
                    <BiTimeFive className='text-blue-500 mr-2'/>
                    <p className='text-blue-500 mr-1'>Duration:</p>
                    <p>6 Months</p>
                </li>
                <li className='flex items-center'>
                    <BsFillFlagFill className='text-blue-500 mr-2'/>
                    <p className='text-blue-500 mr-1'>Start date:</p>
                    <p>On Enrollment</p>
                </li>
                <li className='flex items-center'>
                    <GiMuscleUp className='text-blue-500 mr-2'/>
                    <p className='text-blue-500 mr-1'>Commitment:</p>
                    <p>8-15 Hours/week</p>
                </li>
                <li className='flex items-center'>
                    <BsFillFileEarmarkFill className='text-blue-500 mr-2'/>
                    <p className='text-blue-500 mr-1'>Free Materials</p>
                </li>
                <li className='flex items-center'>
                    <BiSupport className='text-blue-500 mr-2'/>
                    <p className='text-blue-500 mr-1'>24/7 Support</p>
                </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  export default Details